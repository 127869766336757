<template>
    <div class="home">
      <div class="pt20"></div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="排序方式">
            <el-select style="width:150px" v-model="formInline.sort" placeholder="状态">
                <el-option label="时间" :value="1"></el-option>
                <el-option label="达人值" :value="2"></el-option>
            </el-select>
            </el-form-item>
             <el-form-item label="是否为运营账号">
            <el-select style="width:100px" v-model="formInline.is_real" placeholder="状态">
                <el-option label="是" :value="0"></el-option>
                <el-option label="否" :value="1"></el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="加入时间">
                <el-date-picker
                    v-model="timevalue"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>
            <el-form-item style="font-weight: bold;" label="关键字">
            <el-input v-model="formInline.query" placeholder="手机号、昵称、用户编号"></el-input>
            </el-form-item>
            <el-form-item>
            <el-button type="primary" @click="onSubmit('CHA')">查询</el-button>
            <el-button  type="primary" @click="leadingout()">导出</el-button>
            <el-button  type="primary" @click="addusder()">新增</el-button>
            </el-form-item>
              
            
      </el-form> 
      <div class="pt20"></div>
      <el-table
      v-loading="loading"
        :data="tableData"
        style="width: 100%"
        @selection-change="handleSelectionChange" >
        <el-table-column label="序号" :index="typeIndex" type="index" show-overflow-tooltip width="50">
        </el-table-column>
        <el-table-column
          prop="headimg" 
          label="头像" >
          <template slot-scope="scope">
             <el-image 
                style="width: 30px; height: 30px"
                :src="scope.row.headimg">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="nickname"
          label="昵称"
          width="180">
        </el-table-column>
        <el-table-column
          prop="phone"
          label="用户账号">
        </el-table-column>

        <el-table-column
          prop="uid"
          label="用户编号">
        </el-table-column>
        <el-table-column
          prop="is_authentication"
          label="认证用户">
          <template slot-scope="scope">
            {{scope.row.is_authentication==1?'是':'否'}}
          </template>
        </el-table-column>
        <el-table-column
          prop="talent_integral"
          label="达人值">
        </el-table-column>
        <el-table-column
          prop="article_count"
          label="内容总数">
        </el-table-column>
        <el-table-column
          prop="fans_user_count"
          label="粉丝数">
        </el-table-column>
        <el-table-column
          prop="violation_count"
          label="违规次数">
        </el-table-column>
        <el-table-column
          prop="report_count"
          label="被举报次数">
        </el-table-column>
        <el-table-column
        width="180"
          label="操作">
          <template  slot-scope="scope">
            <el-button v-if="scope.row.is_real == 0" @click="goedit2(scope.row)" size="mini" type="info" plain>编辑</el-button>
            <el-button v-if="detail == 1" @click="goedit(scope.row)" size="mini" type="info" plain>查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pt20"></div>
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="current_page"
      :page-size="per_page"
      layout="total,prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
import formatDate from '../../lib/dateFormat'
import globla from '../../globla.js'

export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      loading:true,
      edit:location.edit,
      detail:location.detail,
      total:0,
      per_page: 0,
      current_page:1,
      tableData: [],
      formInline: {
        sdate:'',
        edate:'',
        query:'',
        sort:1,
        page:1,
        is_real:1,
      },
      initdata:{
        sdate:'',
        edate:'',
        query:'',
        sort:1,
        page:1,
        is_real:1,
      },
      timevalue:'',
      // 选中数组
      ghs: [],
     //选中的记录数量
     selectedNum:0,
     pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {  
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
    }
  },
  methods: {
      leadingout(){
    let token = localStorage.getItem('Authorization');
    //config.headers.common['Authorization'] ='Bearer '+token;
    
    window.open(globla.SERVE_URL+'/'+config.consumerExport+'?sdate='+this.formInline.sdate+'&edate='+this.formInline.edate+
    '&query='+this.formInline.query+'&page='+this.formInline.page+'&sort='+this.formInline.sort
    +'&api_token='+token, "_blank");
      },
      onSubmit(CHA){
        let _this = this;
        if(CHA == 'CHA'){
          this.formInline.page = 1;
        }
          _this.formInline.sdate = '';
          _this.formInline.edate = '';
          if(this.timevalue){
            this.formInline.sdate = formatDate(this.timevalue[0]);
            this.formInline.edate = formatDate(this.timevalue[1]);
          }
        _this.loading = true;
        axios.get(config.consumer,{params:this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
    setpage(page){
      this.total = page.total;
      this.per_page = page.per_page;
      this.current_page = page.current_page;
    },
      handleCurrentChange(val) {
        this.formInline.page = val;
        this.onSubmit();
        document.getElementsByClassName('el-main')[0].scrollTop = 0;
      },
      goNav(url){
        sessionStorage.setItem("formInline", JSON.stringify(this.formInline));
        this.$router.push(url);
      },typeIndex(index) {
      let _this = this; //处理分页数据的 index
      return (_this.formInline.page - 1) * 20 + index + 1;
    },
    addusder(){
      this.$router.push('/usermanagement/adduser');
    },
      goedit(obj){
        sessionStorage.setItem("edit", JSON.stringify(obj));
        sessionStorage.setItem("formInline", JSON.stringify(this.formInline));
       // this.$router.push('/usermanagement/usermanagementedit');
        sessionStorage.setItem("pathname", location.pathname);
        window.open('/usermanagement/usermanagementedit');
      },
      goedit2(obj){
        sessionStorage.setItem("edit", JSON.stringify(obj));
        sessionStorage.setItem("formInline", JSON.stringify(this.formInline));
       // this.$router.push('/usermanagement/edituser');
        sessionStorage.setItem("pathname", location.pathname);
        window.open('/usermanagement/edituser');
      },
    handleSelectionChange(selection) {// 多选框选中数据
        //获取所有选中项的gh(工号)属性的值
        this.ghs = selection.map(item => item.user_id)
        //获取所有选中项数组的长度
        this.selectedNum = selection.length
      },
    init(){
        let _this = this;
        let a = sessionStorage.getItem('formInline');
        this.formInline = a?JSON.parse(a):this.initdata;
        axios.get(config.consumer,{params:_this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    }
  },
  created(){
      this.init();
  }
}
</script>
<style scoped>
.left{
    width: 335px;
}
</style>
